import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	useTable,
	useGlobalFilter,
	useFilters,
	usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import profile from "../../../images/avatar/1.jpg";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PmService from "../../../services/PmService";
import moment from "moment";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import { ColumnFilter } from "../FilteringTable/ColumnFilter";
import "../FilteringTable/filtering.css";
import { format } from "date-fns";
import { Cell } from "recharts";
import Select from "react-select";
import emojiRegex from 'emoji-regex';
import Notification from '../../../icons/setup/notification.svg';
import Guide from '../../../icons/setup/guide.svg';
import Order from '../../../icons/setup/order.svg';
import Service from '../../../icons/setup/service.svg';
import Checklist from '../../../icons/setup/checklist.svg';
import Citytax from '../../../icons/setup/city-tax.svg';
import Damage from '../../../icons/setup/damage.svg';
import GuestInfo from '../../../icons/setup/guestinfo.svg';
import ID from '../../../icons/setup/id.svg';
import Invoice from '../../../icons/setup/invoice.svg';
import SafetyDeposit from '../../../icons/setup/safety-deposit.svg';
import Team from '../../../icons/setup/team.svg';
import ServiceNotification from "../ServiceNotification";
import GlobalInventory from "./GlobalInventory";
import Inventory from "../Property/Inventory";
import EGuide from "../Property/E-Guide";

function Setup() {
	
	const [loader, setLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("Notification");

    const [notificationTableVisible, setNotificationTableVisible] = useState(false);
    const [eguideTableVisible, setEguideTableVisible] = useState(false);
    const [guestinfoVisible, setGuestinfoVisible] = useState(false);
    const [inventoryTableVisible, setInventoryTableVisible] = useState(false);
    const [cityTaxVisible, setCityTaxVisible] = useState(false);
    const [safetyDepositOptions, setSafetyDepositOptions] = useState(false);

    // Global settings state
    const [notification_setting, setNotification_setting,] = useState(true);
    const [global_guide_book_setting, setGlobal_guide_book_setting,] = useState(false);
    const [order_setting, setOrder_setting,] = useState(false);
    const [global_services_setting, setGlobal_services_setting,] = useState(false);
    const [checkin_services_setting, setCheckin_services_setting,] = useState(true);
    const [global_guest_info_setting, setGlobal_guest_info_setting,] = useState(false);
    const [main_guest_info_setting, setMain_guest_info_setting,] = useState(false);
    const [adult_info_setting, setAdult_info_setting,] = useState(false);
    const [inventory_setting, setInventory_setting,] = useState(false);
    const [previous_damage_setting, setPrevious_damage_setting,] = useState(true);
    const [id_recognition_setting, setId_recognition_setting,] = useState(false);
    const [checkout_services_setting, setCheckout_services_setting,] = useState(true);
    const [damage_setting, setDamage_setting,] = useState(true);
    const [while_stay_services_setting, setWhile_stay_services_setting] = useState(true);
    const [global_city_tax_setting, setGlobal_city_tax_setting] = useState(false);
    const [global_adults_city_tax, setGlobal_adults_city_tax] = useState(0);
    const [global_kids_city_tax, setGlobal_kids_city_tax] = useState(0);
    const [global_safety_deposit_setting,  setGlobal_safety_deposit_setting] = useState(false);
    const [global_deposit_amount,  setGlobal_deposit_amount] = useState(0);
    const [global_auto_refund_setting,  setGlobal_auto_refund_setting] = useState(false);
    const [global_auto_refund_days,  setGlobal_auto_refund_days] = useState(7);
    const [global_manual_refund_setting,  setGlobal_manual_refund_setting] = useState(false);

    const handleNotificationTableToggle = () => {
        setNotificationTableVisible((prevState) => !prevState);
    };

    const handleEguideToggle = () => {
        setEguideTableVisible((prevState) => !prevState);
    };

    const handleInventoryToggle = () => {
        setInventoryTableVisible((prevState) => !prevState);
    };

    const [inventoryItem, setInventoryItem] = useState('');
    const [inventoryList, setInventoryList] = useState([]);

    const addItem = () => {
        if (inventoryItem.trim()) {
            setInventoryList([...inventoryList, inventoryItem.trim()]);
            setInventoryItem('');
        }
    };

    const removeItem = (index) => {
        setInventoryList(inventoryList.filter((_, i) => i !== index));
    };

    const handleSettingChange = (e) => {
        const name = e.target.name;
    
        if (name === 'notification_setting') {
            setNotification_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ notification_setting: newState });
                return newState;
            });
        } else if (name === 'global_guide_book_setting') {
            setGlobal_guide_book_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ global_guide_book_setting: newState });
                return newState;
            });
        } else if (name === 'order_setting') {
            setOrder_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ order_setting: newState });
                return newState;
            });
        } else if (name === 'global_services_setting') {
            setGlobal_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ global_services_setting: newState });
                return newState;
            });
        } else if (name === 'checkin_services_setting') {
            setCheckin_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ checkin_services_setting: newState });
                return newState;
            });
        } else if (name === 'global_guest_info_setting') {
            setGlobal_guest_info_setting((prevState) => {
                const newState = !prevState;
                if (prevState) {
                    setMain_guest_info_setting(false);
                    updatePmSettings({ main_guest_info_setting: false });

                    setAdult_info_setting(false);
                    updatePmSettings({ adult_info_setting: false });
                }
                updatePmSettings({ global_guest_info_setting: newState });
                return newState;
            });
        } else if (name === 'main_guest_info_setting') {
            setMain_guest_info_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setAdult_info_setting(false);
                    updatePmSettings({ adult_info_setting: false });
                }
                updatePmSettings({ main_guest_info_setting: newState });
                return newState;
            });
        } else if (name === 'adult_info_setting') {
            setAdult_info_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setMain_guest_info_setting(false);
                    updatePmSettings({ main_guest_info_setting: false });
                }
                updatePmSettings({ adult_info_setting: newState });
                return newState;
            });
        } else if (name === 'inventory_setting') {
            setInventory_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ inventory_setting: newState });
                return newState;
            });
        } else if (name === 'previous_damage_setting') {
            setPrevious_damage_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ previous_damage_setting: newState });
                return newState;
            });
        } else if (name === 'id_recognition_setting') {
            setId_recognition_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ id_recognition_setting: newState });
                return newState;
            });
        } else if (name === 'checkout_services_setting') {
            setCheckout_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ checkout_services_setting: newState });
                return newState;
            });
        } else if (name === 'damage_setting') {
            setDamage_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ damage_setting: newState });
                return newState;
            });
        } else if (name === 'while_stay_services_setting') {
            setWhile_stay_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ while_stay_services_setting: newState });
                return newState;
            });
        } else if (name === 'global_city_tax_setting') {
            setGlobal_city_tax_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ global_city_tax_setting: newState });
                return newState;
            });
        } else if (name === 'adult-tax') {
            setGlobal_adults_city_tax(e.target.value);
            
        } else if (name === 'kids-tax') {
            setGlobal_kids_city_tax(e.target.value);
            
        } else if (name === 'global_safety_deposit_setting') {
            setGlobal_safety_deposit_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ global_safety_deposit_setting: newState });
                return newState;
            });
        } else if (name === 'global_deposit_amount') {
            updatePmSettings({ global_deposit_amount: global_deposit_amount });
        } else if (name === 'global_auto_refund_setting') {
            setGlobal_auto_refund_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setGlobal_manual_refund_setting(false);
                    updatePmSettings({ global_manual_refund_setting: false });
                }
                updatePmSettings({ global_auto_refund_setting: newState });
                return newState;
            });
        } else if (name === 'global_manual_refund_setting') {
            setGlobal_manual_refund_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setGlobal_auto_refund_setting(false);
                    updatePmSettings({ global_auto_refund_setting: false });
                }
                updatePmSettings({ global_manual_refund_setting: newState });
                return newState;
            });
        } else if (name === 'global_auto_refund_days') {
            setGlobal_auto_refund_days(e.target.value);
            updatePmSettings({ global_auto_refund_days: e.target.value })
        }
    };

    const updatePmSettings = (passData) => {
        passData.property_id = 0;

        PmService.UpdatePmSettings(passData)
            .then((res) => {
                if (res.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Setting has been changed",
                    });
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const fetchPmSettings = () => {
        const property_id = 0;

        PmService.GetPmSettings(property_id)
            .then((res) => {
                const data = res.data;

                setNotification_setting(!!data.notification_setting);
                setGlobal_guide_book_setting(!!data.global_guide_book_setting);
                setOrder_setting(!!data.order_setting);
                setGlobal_services_setting(!!data.global_services_setting);
                setCheckin_services_setting(!!data.checkin_services_setting);
                setGlobal_guest_info_setting(!!data.global_guest_info_setting);
                setMain_guest_info_setting(!!data.main_guest_info_setting);
                setAdult_info_setting(!!data.adult_info_setting);
                setInventory_setting(!!data.inventory_setting);
                setPrevious_damage_setting(!!data.previous_damage_setting);
                setId_recognition_setting(!!data.id_recognition_setting);
                setCheckout_services_setting(!!data.checkout_services_setting);
                setDamage_setting(!!data.damage_setting);
                setWhile_stay_services_setting(!!data.while_stay_services_setting);
                setGlobal_city_tax_setting(!!data.global_city_tax_setting);
                setGlobal_adults_city_tax(data.global_adults_city_tax);
                setGlobal_kids_city_tax(data.global_kids_city_tax);
                setGlobal_safety_deposit_setting(!!data.global_safety_deposit_setting);
                setGlobal_deposit_amount(data.global_deposit_amount);
                setGlobal_auto_refund_setting(!!data.global_auto_refund_setting);
                setGlobal_auto_refund_days(data.global_auto_refund_days);
                setGlobal_manual_refund_setting(!!data.global_manual_refund_setting);

                console.log("Settings loaded successfully");
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {
        fetchPmSettings();
    }, []);
	
	return loader ? (
		<Loader />
	) : (
    <>
		<div className="form-head page-titles d-flex  align-items-center">
			<div className="me-auto  d-lg-block">
			<h4 className="mb-1">Setup</h4>
			<ol className="breadcrumb">
				<li className="breadcrumb-item active">
				<Link to="/dashboard">Dashboard</Link>
				</li>
				<li className="breadcrumb-item">
				<Link to="/setup">Setup</Link>
				</li>
			</ol>
			</div>
		</div>

        {/* Tab View */}
        <div className="tab-view">
            <div className="tab-headers d-flex align-items-center mb-3">
            <button 
                className={`tab-button ${activeTab === 'Notification' ? 'active' : ''}`}
                onClick={() => setActiveTab("Notification")}
                style={{ marginRight: "10px" }}
            >
                Notification
            </button>
            <button 
                className={`tab-button ${activeTab === 'Guide book' ? 'active' : ''}`}
                onClick={() => setActiveTab("Guide book")}
                style={{ marginRight: "10px" }}
            >
                Guide Book
            </button>
            <button 
                className={`tab-button ${activeTab === 'Order' ? 'active' : ''}`}
                onClick={() => setActiveTab("Order")}
                style={{ marginRight: "10px" }}
            >
                Order
            </button>
            <button 
                className={`tab-button ${activeTab === 'Services' ? 'active' : ''}`}
                onClick={() => setActiveTab("Services")}
                style={{ marginRight: "10px" }}
            >
                Services
            </button>
            <button 
                className={`tab-button ${activeTab === 'Check-in' ? 'active' : ''}`}
                onClick={() => setActiveTab("Check-in")}
                style={{ marginRight: "10px" }}
            >
                Check-in
            </button>
            <button 
                className={`tab-button ${activeTab === 'Check-out' ? 'active' : ''}`}
                onClick={() => setActiveTab("Check-out")}
                style={{ marginRight: "10px" }}
            >
                Check-out
            </button>
            <button 
                className={`tab-button ${activeTab === 'While stay' ? 'active' : ''}`}
                onClick={() => setActiveTab("While stay")}
                style={{ marginRight: "10px" }}
            >
                While stay
            </button>
            <button 
                className={`tab-button ${activeTab === 'City tax' ? 'active' : ''}`}
                onClick={() => setActiveTab("City tax")}
                style={{ marginRight: "10px" }}
            >
                City tax
            </button>
            <button 
                className={`tab-button ${activeTab === 'Safety deposite' ? 'active' : ''}`}
                onClick={() => setActiveTab("Safety deposite")}
                style={{ marginRight: "10px" }}
            >
                Safety deposite
            </button>
            <button 
                className={`tab-button ${activeTab === 'Invoices' ? 'active' : ''}`}
                onClick={() => setActiveTab("Invoices")}
                style={{ marginRight: "10px" }}
            >
                Invoices
            </button>
            <button 
                className={`tab-button ${activeTab === 'Team' ? 'active' : ''}`}
                onClick={() => setActiveTab("Team")}
            >
                Team
            </button>
            </div>
        </div>

		<div className="row">
			<div className="col-xl-12">
				<div className="card">
					<div className="card-body">
                        {
                            activeTab === 'Notification' && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                            name="notification_setting"
                                            onClick={handleSettingChange}
                                            checked={notification_setting}
                                        />
                                        <div className="feature-details">
                                            <img src={Notification} className="feature-icon" />
                                            <div className="feature-title">Notification</div>
                                            <div className="feature-description">
                                                On top of the standard inbuild SNAPIN notification create your own notifiaction to boost your revenu 
                                            </div>
                                            
                                            <div className="feature-price">
                                                <Button className="btn btn-primary shadow btn-xs sharp me-1" onClick={handleNotificationTableToggle}>
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            activeTab === 'Guide book' && (
                                <div className="feature-card">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        className="feature-toggle"
                                        name="global_guide_book_setting"
                                        onClick={handleSettingChange}
                                        checked={global_guide_book_setting}
                                    />
                                    <div className="feature-details">
                                        <img src={Guide} className="feature-icon" />
                                        <div className="feature-title">Guide book</div>
                                        <div className="feature-description">
                                            Allow your guest to see multiple guides (entrance, equipment functionalities, wifi set-up, etc..)
                                        </div>
                                        <div className="feature-offer">
                                            <span></span>
                                            <span className="feature-price">
                                                <Button
                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                    onClick={handleEguideToggle}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
						{
                            activeTab === 'Order' && (
                                <div className="feature-card">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        className="feature-toggle"
                                        name="order_setting"
                                        onClick={handleSettingChange}
                                        checked={order_setting}
                                    />
                                    <div className="feature-details">
                                        <img src={Order} className="feature-icon" />
                                        <div className="feature-title">Order</div>
                                        <div className="feature-description">
                                            Approve your service orders before payment processing
                                        </div>
                                        {/* <div className="feature-offer">
                                            <span></span>
                                            <span className="feature-price">
                                                <Button
                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }
						{
                            activeTab === 'Services' && (
                                <div className="feature-card">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        className="feature-toggle"
                                        name="global_services_setting"
                                        onClick={handleSettingChange}
                                        checked={global_services_setting}
                                    />
                                    <div className="feature-details">
                                        <img src={Service} className="feature-icon" />
                                        <div className="feature-title">Services</div>
                                        <div className="feature-description">
                                            Activate SNAPIN service upselling 
                                        </div>
                                        {/* <div className="feature-offer">
                                            <span></span>
                                            <span className="feature-price">
                                                <Button
                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }
						{
                            activeTab === 'Check-in' && (
                                <>
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="checkin_services_setting"
                                                onClick={handleSettingChange}
                                                checked={checkin_services_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Service} className="feature-icon" />
                                                <div className="feature-title">Services</div>
                                                <div className="feature-description">
                                                    Activate SNAPIN service upselling during checkin flow
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="global_guest_info_setting"
                                                onClick={handleSettingChange}
                                                checked={global_guest_info_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Guest information management</div>
                                                <div className="feature-description">
                                                    Management which guests must register in SNAPIN
                                                </div>
                                                <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                            onClick={() => {
                                                                setGuestinfoVisible((prevState) => !prevState);
                                                                setInventoryTableVisible(false);
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="inventory_setting"
                                                onClick={handleSettingChange}
                                                checked={inventory_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Checklist} className="feature-icon" />
                                                <div className="feature-title">Inventory check-list</div>
                                                <div className="feature-description">
                                                    Create your own entrance inventory checklist
                                                </div>
                                                <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                            onClick={() => {
                                                                handleInventoryToggle();
                                                                setGuestinfoVisible(false);
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="previous_damage_setting"
                                                onClick={handleSettingChange}
                                                checked={previous_damage_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Damage} className="feature-icon" />
                                                <div className="feature-title">Previous Damage</div>
                                                <div className="feature-description">
                                                    Allow guest to register previous damage while checkin
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="id_recognition_setting"
                                                onClick={handleSettingChange}
                                                checked={id_recognition_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={ID} className="feature-icon" />
                                                <div className="feature-title">ID Recognition</div>
                                                <div className="feature-description">
                                                    Allow your guets to use ID recognition during profile and checkin registration
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
						{
                            activeTab === 'Check-out' && (
                                <>
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="checkout_services_setting"
                                                onClick={handleSettingChange}
                                                checked={checkout_services_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Service} className="feature-icon" />
                                                <div className="feature-title">Services</div>
                                                <div className="feature-description">
                                                    Activate SNAPIN service upselling during checkout flow
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="damage_setting"
                                                onClick={handleSettingChange}
                                                checked={damage_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Damage} className="feature-icon" />
                                                <div className="feature-title">Damage</div>
                                                <div className="feature-description">
                                                    Register damage while on stay to ensure safety deposite and quick repair by the maintenance team 
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
						{
                            activeTab === 'While stay' && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                            name="while_stay_services_setting"
                                            onClick={handleSettingChange}
                                            checked={while_stay_services_setting}
                                        />
                                        <div className="feature-details">
                                            <img src={Service} className="feature-icon" />
                                            <div className="feature-title">Services</div>
                                            <div className="feature-description">
                                                Activate SNAPIN service upselling during while on stay flow
                                            </div>
                                            {/* <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </>
                            )
                        }
						{
                            // activeTab === 'City tax' && (
                            //     <>
                            //         <div className="feature-card">
                            //             <Form.Check
                            //                 type="switch"
                            //                 id="custom-switch"
                            //                 label=""
                            //                 className="feature-toggle"
                            //                 name="global_city_tax_setting"
                            //                 onClick={handleSettingChange}
                            //                 checked={global_city_tax_setting}
                            //             />
                            //             <div className="feature-details">
                            //                 <img src={Citytax} className="feature-icon" />
                            //                 <div className="feature-title">City tax</div>
                            //                 <div className="feature-description">
                            //                     Let SNAPIN handle your city tax payment during checkin flow 
                            //                 </div>
                            //                 <div className="feature-offer">
                            //                     <span></span>
                            //                     <span className="feature-price">
                            //                         <Button
                            //                             className="btn btn-primary shadow btn-xs sharp me-1"
                            //                             onClick={() => setCityTaxVisible((prevState) => !prevState)}
                            //                         >
                            //                             <i className="fa fa-pencil"></i>
                            //                         </Button>
                            //                     </span>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </>
                            // )
                        }
						{
                            activeTab === 'Safety deposite' && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                            name="global_safety_deposit_setting"
                                            onClick={handleSettingChange}
                                            checked={global_safety_deposit_setting}
                                        />
                                        <div className="feature-details">
                                            <img src={SafetyDeposit} className="feature-icon" />
                                            <div className="feature-title">Safety Deposit</div>
                                            <div className="feature-description">
                                                Protect you properties with a safety deposit taken during checkin flow and decide when and how it will be refund 
                                            </div>
                                            <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                        onClick={() => setSafetyDepositOptions((prev) => !prev)}
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
						{
                            activeTab === 'Invoices' && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                        />
                                        <div className="feature-details">
                                            <img src={Invoice} className="feature-icon" />
                                            <div className="feature-title">Invoices</div>
                                            <div className="feature-description">
                                                Generate directly invoice to your invoice management system
                                            </div>
                                            <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
						{
                            activeTab === 'Team' && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                        />
                                        <div className="feature-details">
                                            <img src={Team} className="feature-icon" />
                                            <div className="feature-title">Team</div>
                                            <div className="feature-description">
                                                Create multiple Team member to manage your property, allow them to receive information 
                                            </div>
                                            <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
					</div>
                    {
                        notificationTableVisible && activeTab == 'Notification' && (
                            <>
                                <ServiceNotification />
                            </>
                        )
                    }

                    {
                        guestinfoVisible && activeTab == 'Check-in' && (
                            <>
                                <div className="card-body">
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="main_guest_info_setting"
                                                onClick={handleSettingChange}
                                                checked={main_guest_info_setting}
                                                disabled={!global_guest_info_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Include Main Guest Only</div>
                                                <div className="feature-description">
                                                    Management which guests must register in SNAPIN 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="adult_info_setting"
                                                onClick={handleSettingChange}
                                                checked={adult_info_setting}
                                                disabled={!global_guest_info_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Include Adult Only</div>
                                                <div className="feature-description">
                                                    Management which guests must register in SNAPIN 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }

                    {
                        // activeTab == 'City tax' && cityTaxVisible && (
                        //     <>
                        //         <div className="card-body">
                        //             <div className="feature-container">
                        //                 <div className="feature-card">
                        //                     <div className="feature-details">
                        //                         <div className="feature-title">Adults/day</div>
                        //                         <div className="feature-description">
						// 							<input
						// 								type="number"
						// 								className="form-control"
						// 								name="adult-tax"
						// 								min="0"
                        //                                 onChange={handleSettingChange}
                        //                                 value={global_adults_city_tax}
						// 							/>
                        //                         </div>
						// 						<div className="feature-offer mb-4">
						// 							<span></span>
						// 							<span className="feature-price" style={{ bottom: "8px" }}>
						// 								<Button
						// 									className="btn btn-primary btn-sm shadow sharp mt-3"
						// 									style={{ width: "70px", height: "25px" }}
                        //                                     onClick={() => { updatePmSettings({ global_adults_city_tax: global_adults_city_tax }); }}
						// 								>
						// 									Save
						// 								</Button>
						// 							</span>
						// 						</div>
                        //                     </div>
                        //                 </div>
                        //                 <div className="feature-card">
                        //                     <div className="feature-details">
                        //                         <div className="feature-title">Kids/day</div>
                        //                         <div className="feature-description">
						// 							<input
						// 								type="number"
						// 								className="form-control"
						// 								name="kids-tax"
						// 								min="0"
                        //                                 onChange={handleSettingChange}
                        //                                 value={global_kids_city_tax}
						// 							/>
                        //                         </div>
						// 						<div className="feature-offer mb-4">
						// 							<span></span>
						// 							<span className="feature-price" style={{ bottom: "8px" }}>
						// 								<Button
						// 									className="btn btn-primary btn-sm shadow sharp mt-3"
						// 									style={{ width: "70px", height: "25px" }}
                        //                                     onClick={() => { updatePmSettings({ global_kids_city_tax: global_kids_city_tax }); }}
						// 								>
						// 									Save
						// 								</Button>
						// 							</span>
						// 						</div>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //     </>
                        // )
                    }

                    {
                        global_safety_deposit_setting
                        && safetyDepositOptions
                        && activeTab == 'Safety deposite' && (
                            <>
                                <div className="card-body">
                                    <div className="feature-container mb-4">
                                        <label className="form-label mt-2">Amount:</label>
                                        <div>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name=""
                                                value={global_deposit_amount}
                                                onChange={(e) => setGlobal_deposit_amount(e.target.value)}
                                                min='0'
                                            />
                                        </div>
                                        <Button
                                            name="global_deposit_amount"
                                            onClick={handleSettingChange}
                                            disabled={global_deposit_amount >= 0 ? false : true}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="global_auto_refund_setting"
                                                onClick={handleSettingChange}
                                                checked={global_auto_refund_setting}
                                                disabled={!global_safety_deposit_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Auto refund</div>
                                                <div className="feature-description">
                                                    Refund will be given after selected days
                                                </div>
                                                <div className="feature-description">
                                                    <select
                                                        name="global_auto_refund_days"
                                                        className="form-select form-control-lg rounded"
                                                        value={global_auto_refund_days}
                                                        style={{
                                                            cursor: "pointer",
                                                            width: "fit-content",
                                                            fontSize: "14px",
                                                            backgroundColor: "var(--primary)",
                                                            color: "white",
                                                            marginRight: "10px"
                                                        }}
                                                        onChange={handleSettingChange}
                                                        disabled={global_manual_refund_setting || !global_auto_refund_setting}
                                                    >
                                                        <option value="3">3 days</option>
                                                        <option value="5">5 days</option>
                                                        <option value="7">7 days</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="global_manual_refund_setting"
                                                onClick={handleSettingChange}
                                                checked={global_manual_refund_setting}
                                                disabled={!global_safety_deposit_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Manual refund</div>
                                                <div className="feature-description">
                                                    PM decides to give manual refund
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }

				</div>
			</div>
        </div>
        
        {
            eguideTableVisible && activeTab == 'Guide book' && (
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <EGuide />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        {
            inventoryTableVisible && activeTab == 'Check-in' && (
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <Inventory />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
    </>
  );
}

export default Setup;
