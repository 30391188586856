import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Collapse, Card, Button, Modal, Table, ProgressBar, Form } from "react-bootstrap";

//** Import Image */
import ImageGallery from "../Omah/PropertyDetails/ImageGallery";

import PmService from "../../../services/PmService";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import EGuide from "./E-Guide";
import Inventory from "./Inventory";

import Notification from "../../../icons/setup/notification.svg";
import Guide from "../../../icons/setup/guide.svg";
import Order from "../../../icons/setup/order.svg";
import Service from "../../../icons/setup/service.svg";
import Checklist from "../../../icons/setup/checklist.svg";
import Citytax from "../../../icons/setup/city-tax.svg";
import Damage from "../../../icons/setup/damage.svg";
import GuestInfo from "../../../icons/setup/guestinfo.svg";
import ID from "../../../icons/setup/id.svg";
import Invoice from "../../../icons/setup/invoice.svg";
import SafetyDeposit from "../../../icons/setup/safety-deposit.svg";
import Team from "../../../icons/setup/team.svg";
import ServiceNotification from "../ServiceNotification";
// import GlobalInventory from "./GlobalInventory";
// import Inventory from "../Property/Inventory";
// import EGuide from "../Property/E-Guide";

function PropertyDetails() {
    const { id } = useParams();
    const propertyId = atob(id);
    const [loader, setLoader] = useState(false);
    const [subServiceLoader, setSubServiceLoader] = useState(false);
    const [dettachSubServiceModal, setDettachSubServiceModal] = useState(false);
    const [attachSubServiceModal, setAttachSubServiceModal] = useState(false);
    const [addSubServiceModal, setAddSubServiceModal] = useState(false);
    const [addPmDescModal, setAddPmDescModal] = useState(false);
    const [propertyDetail, setPropertyDetail] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const descriptionRef = useRef(null);
    const [mainServiceList, setMainServiceList] = useState([]);
    const [subServices, setSubServices] = useState([]);
    const [subServicesOrg, setSubServicesOrg] = useState([]);
    const [subServicesIndex, setSubServicesIndex] = useState(null);
    const [passingData, setPassingData] = useState([]);
    const [mainServiceIdValue, setMainServiceIdValue] = useState(null);
    const [pmDesc, setPmDesc] = useState("");
    let errorsObj = { pmDesc: "" };
    const [errors, setErrors] = useState(errorsObj);
    const [pmDescAdd, setPmDescAdd] = useState(false);
    const [propertyStatus, setPropertyStatus] = useState(null);
    const [confirmModalOpen, setConfirmModal] = useState(false);
    const [subServiceDetail, setSubServiceDetail] = useState([]);
    const [subServiceDetailModal, setSubServiceDetailModal] = useState(false);
    const [editPropertyModal, setEditPropertyModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [formData, setFormData] = useState({
        bookingIcalLink: "",
        airbnbIcalLink: "",
        airbnbPropertyLink: "",
        pdfLink: "",
    });
    const [activeTab, setActiveTab] = useState("Notification");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors({
            ...errors,
            [name]: "",
        });
    };

    const handleCheckboxChange = (event, index) => {
        setSubServicesIndex(index);
        const updatedServices = subServices.map((item) => {
            if (item.id === index) {
                return { ...item, service_added: !item.service_added };
            }
            return item;
        });
        setSubServices(updatedServices);
        if (!event.target.checked) {
            setDettachSubServiceModal(true);
        } else {
            setAttachSubServiceModal(true);
        }
    };

    const handleTopServiceCheckboxChange = async (event, index) => {
        const sub_service = subServices.filter((item) => item.id === index);

        const passData = {
            property_id: propertyId,
            service_id: sub_service[0].service_id,
            sub_service_id: sub_service[0].id,
        };

        PmService.GetTopServiceAvailability(passData)
            .then((resp) => {
                if (resp.data) {
                    Swal.fire({
                        icon: "success",
                        title: "Service changed",
                        text: "Service changes done",
                    });

                    fetchSubServicList(sub_service[0].service_id);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops!",
                        text: "Top 5 services are already added",
                    });
                }
            })
            .catch((error) => console.log(error));
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const getMainServicesByProeprty = () => {
        PmService.GetMainServicesByProperty(propertyId)
            .then((resp) => {
                if (resp.success) {
                    setMainServiceList(resp?.data?.list);
                }
            })
            .catch((error) => {
                console.log("error=-=-=-=-=-=-=-=-=-=-=-", error);
            });
    };

    useEffect(() => {
        setLoader(true);
        PmService.GetPropertyById(propertyId)
            .then((resp) => {
                console.log("Respo = ", resp);
                if (resp.success) {
                    setPropertyDetail(resp.data.detail);
                    setPropertyStatus(resp.data.detail.status);
                }
            })
            .catch((error) => {
                console.log("error = ", error);
            })
            .finally(() => {
                setLoader(false);
            });

        getMainServicesByProeprty();
    }, []);

    const handleAddService = (mainServiceId) => {
        setSubServices([]);
        setPassingData([]);
        setPmDesc("");
        setSubServicesIndex(null);
        setMainServiceIdValue(mainServiceId);
        console.log("-=-=-mainServiceId = ", mainServiceId);
        setAddSubServiceModal(true);
        setSubServiceLoader(true);
        fetchSubServicList(mainServiceId);
    };

    const fetchSubServicList = (mainServiceId) => {
        PmService.GetSubServicesByProperty(propertyId, mainServiceId)
            .then((resp) => {
                console.log("response =  ", resp);
                if (resp.success) {
                }
                setSubServices(resp.data.list);
                setSubServicesOrg(resp.data.list);
            })
            .catch((error) => {
                console.log("error =  ", error);
            })
            .finally(function () {
                setSubServiceLoader(false);
            });
    };

    const handleAttachService = (status, option) => {
        if (status == false && subServicesIndex != null) {
            const updatedServices = subServices.map((item) => {
                if (item.id === subServicesIndex) {
                    return { ...item, service_added: !item.service_added };
                }
                return item;
            });
            setSubServices(updatedServices);
        } else if (status == true && subServicesIndex != null) {
            console.log("=subServicesIndex = ", subServicesIndex);
            console.log("=option = ", option);
            console.log("=pmDesc = ", pmDesc);
            setPassingData((prevData) => [...prevData, { id: subServicesIndex, option: option, pm_desc: pmDesc }]);
        }
        setAttachSubServiceModal(false);
    };

    const handleDettachService = (status) => {
        if (status == false && subServicesIndex != null) {
            const updatedServices = subServices.map((item) => {
                if (item.id === subServicesIndex) {
                    return { ...item, service_added: !item.service_added };
                }
                return item;
            });
            setSubServices(updatedServices);
        } else if (status == true && subServicesIndex != null) {
            const updatedPassingData = passingData.filter((item) => item.id !== subServicesIndex);
            setPassingData(updatedPassingData);
            const passData = {
                property_id: propertyId,
                service_id: mainServiceIdValue,
                sub_service_id: subServicesIndex,
            };
            console.log("000000passData = ", passData);
            PmService.DettachSubServiceToProperty(passData)
                .then((resp) => {
                    console.log("-====resp=====", resp);
                    if (resp.success) {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Service has been dettached successfully.",
                        });
                        getMainServicesByProeprty();
                    }
                })
                .catch((error) => {
                    console.log("-====error=====", error);
                    Swal.fire({
                        icon: "error",
                        title: "Oops!",
                        text: "Something went wrong. Please try again later.",
                    });
                });
        }
        setDettachSubServiceModal(false);
    };

    const handleAttachSubService = () => {
        if (passingData.length <= 0) {
            alert("please select services to add");
            return;
        }
        const passData = {
            property_id: propertyId,
            service_id: mainServiceIdValue,
            sub_service: passingData,
        };
        setAddSubServiceModal(false);
        PmService.AttachSubServiceToProperty(passData)
            .then((resp) => {
                console.log("-====resp=====", resp);
                if (resp.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Service has been attached successfully.",
                    });
                    getMainServicesByProeprty();
                }
            })
            .catch((error) => {
                console.log("-====error=====", error);
                Swal.fire({
                    icon: "error",
                    title: "Oops!",
                    text: "Something went wrong. Please try again later.",
                });
            });
    };

    const isValidUrl = (url) => {
        const urlPattern = /^https:\/\/([a-z]+\.)?.*$/;
        return !!urlPattern.test(url);
    };

    const isValidPdfUrl = (url) => {
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(:\d{1,5})?(\/.*)?\.pdf(\?.*)?$/i;
        return !!urlPattern.test(url);
    };

    const isValidPdfOrDriveUrl = (url) => {
        const pdfUrlPattern = /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(:\d{1,5})?(\/.*)?\.pdf(\?.*)?$/i;
        const driveLinkPattern =
            /https:\/\/drive\.google\.com\/(?:file\/d\/|open\?id=|uc\?id=)([a-zA-Z0-9_-]+)(?:\/view\?[^#]+)?/;
        return pdfUrlPattern.test(url) || driveLinkPattern.test(url);
    };

    const handleEditProperty = async (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = {};

        if (!formData.airbnbIcalLink.trim()) {
            errorObj.airbnbIcalLink = "Airbnb iCal link is required.";
            error = true;
        } else if (!isValidUrl(formData.airbnbIcalLink.trim())) {
            errorObj.airbnbIcalLink = "Airbnb iCal link is not a valid URL.";
            error = true;
        }

        if (!formData.airbnbPropertyLink.trim()) {
            errorObj.airbnbPropertyLink = "Airbnb property link is required.";
            error = true;
        } else if (!isValidUrl(formData.airbnbPropertyLink.trim())) {
            errorObj.airbnbPropertyLink = "Airbnb property link is not a valid URL.";
            error = true;
        }

        if (formData.bookingIcalLink && !isValidUrl(formData.bookingIcalLink.trim())) {
            errorObj.bookingIcalLink = "Booking iCal link is not a valid URL.";
            error = true;
        }
        if (formData.pdfLink && !isValidPdfOrDriveUrl(formData.pdfLink.trim())) {
            errorObj.pdfLink = "E-PDF link is not a valid URL.";
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        setLoading(true);

        const passData = {
            airbnb_url: formData.airbnbIcalLink,
            property_detail_sync_url: formData.airbnbPropertyLink,
        };
        if (formData.bookingIcalLink) {
            passData.booking_url = formData.bookingIcalLink;
        }
        if (formData.pdfLink) {
            passData.pdf_link = formData.pdfLink;
        }

        let progressValue = 0;
        const increment = () => {
            progressValue = Math.min(progressValue + Math.floor(Math.random() * (8 - 0 + 1) + 0), 95); // Increase progress by 5% at a time
            setProgress(progressValue);
        };

        const interval = setInterval(increment, 1000); // Update progress every second

        try {
            PmService.updateProperty(passData, propertyDetail.id)
                .then((resp) => {
                    if (resp) {
                        clearInterval(interval); // Clear the interval
                        setProgress(100); // Set progress to 100%
                        setTimeout(() => {
                            setLoading(false);
                            setProgress(0);
                            setEditPropertyModal(false);
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "Property details fetched successfully.",
                            }).finally(function () {
                                window.location.reload();
                            });
                        }, 500);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setProgress(0);
                    setEditPropertyModal(false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops!",
                        text: "Something went wrong. Please try again after sometime.",
                    });
                });

            // If the API call succeeds

            // Reset loading state and progress
        } catch (error) {
            console.error("Error during API call", error);
            setLoading(false);
            setProgress(0);
            if (error?.response?.status === 403 || error?.response?.status === 401) {
                console.log("error", error);
            }
        }
    };

    const handlePmDescModal = (subServiceId, value, option) => {
        setPmDesc("");
        if (option == "add") {
            setPmDescAdd(true);
        } else {
            setPmDescAdd(false);
            setPmDesc(value);
        }
        setAddPmDescModal(true);
        setSubServicesIndex(subServiceId);
    };
    const handleAddPmDesc = () => {
        let error = false;
        const errorObj = { ...errorsObj };
        if (pmDesc.trim() == "") {
            errorObj.pmDesc = "Please enter note.";
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }
        const foundItem = subServicesOrg.find((item) => item.id === subServicesIndex);
        console.log("foundItem = ", foundItem);
        if (foundItem) {
            if (foundItem.service_added == 1) {
                console.log("-=-attched and update");
                const passData = {
                    property_id: propertyId,
                    service_id: mainServiceIdValue,
                    sub_service_id: subServicesIndex,
                    pm_desc: pmDesc,
                };
                // console.log("passData=  ", passData)
                console.log("UpdatePmDescForSubServiceToProperty");
                PmService.UpdatePmDescForSubServiceToProperty(passData)
                    .then((resp) => {
                        console.log("resp", resp);
                        if (resp.success) {
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "Note has been added successfully.",
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("-====error=====", error);
                        // Swal.fire({
                        //   icon: "error",
                        //   title: "Oops!",
                        //   text: "Something went wrong. Please try again later.",
                        // });
                    })
                    .finally(function () {
                        setAddPmDescModal(false);
                        fetchSubServicList(mainServiceIdValue);
                    });
            } else {
                console.log("-=-not attched now aattch = ", pmDesc);
                const updatedServices = subServices.map((item) => {
                    if (item.id === subServicesIndex) {
                        return { ...item, pm_desc: pmDesc };
                    }
                    return item;
                });
                setSubServices(updatedServices);
                const updatedpassingData = passingData.map((item) => {
                    if (item.id === subServicesIndex) {
                        return { ...item, pm_desc: pmDesc };
                    }
                    return item;
                });
                setPassingData(updatedpassingData);
            }
            // console.log("foundItem=  ", foundItem)
            // console.log("ifAddedPmDescifAddedPmDescifAddedPmDesc=  ", ifAddedPmDesc)
        }
        setAddPmDescModal(false);
    };

    const handleStatusChange = (e) => {
        console.log("----", e.target.value);
        setPropertyStatus(e.target.value);
        setConfirmModal(true);
    };

    const handleStatusChangeApi = (option) => {
        if (option) {
            console.log("callAPI");
            console.log("propertyStatus = ", propertyStatus);
            console.log("propertyId = ", propertyId);
            const apiData = {
                status: propertyStatus,
            };
            PmService.UpdatePropertyStatus(propertyId, apiData).then((resp) => {
                console.log("resp=  ", resp);
                if (resp.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: resp.message,
                    }).finally(function () {
                        window.location.reload();
                    });
                }
            });
        } else {
            console.log("Nooooooo callAPI");
            if (propertyStatus == 1) {
                setPropertyStatus(2);
            } else {
                setPropertyStatus(1);
            }
        }
        setConfirmModal(false);
    };

    const [notification_setting, setNotification_setting] = useState(true);
    const [local_notification_setting, setLocalNotification_setting] = useState(false);
    const [global_guide_book_setting, setGlobal_guide_book_setting] = useState(false);
    const [local_guide_book_setting, setLocal_guide_book_setting] = useState(false);
    const [order_setting, setOrder_setting] = useState(false);
    const [global_services_setting, setGlobal_services_setting] = useState(false);
    const [checkin_services_setting, setCheckin_services_setting] = useState(true);
    const [global_guest_info_setting, setGlobal_guest_info_setting] = useState(false);
    const [local_guest_info_setting, setLocal_guest_info_setting] = useState(false);
    const [local_main_guest_info_setting, setLocalMain_guest_info_setting] = useState(false);
    const [local_adult_info_setting, setLocalAdult_info_setting] = useState(false);
    const [inventory_setting, setInventory_setting] = useState(false);
    const [local_inventory_setting, setLocalInventory_setting] = useState(false);
    const [previous_damage_setting, setPrevious_damage_setting] = useState(true);
    const [local_previous_damage_setting, setLocalPrevious_damage_setting] = useState(true);
    const [id_recognition_setting, setId_recognition_setting] = useState(false);
    const [local_id_recognition_setting, setLocalId_recognition_setting] = useState(false);
    const [checkout_services_setting, setCheckout_services_setting] = useState(true);
    const [damage_setting, setDamage_setting] = useState(true);
    const [local_damage_setting, setLocalDamage_setting] = useState(true);
    const [while_stay_services_setting, setWhile_stay_services_setting] = useState(true);
    const [local_services_setting, setLocal_services_setting] = useState(false);
    const [local_city_tax_setting, setLocal_city_tax_setting] = useState(false);
    const [local_adults_city_tax, setLocal_adults_city_tax] = useState(0);
    const [local_kids_city_tax, setLocal_kids_city_tax] = useState(0);
    const [local_safety_deposit_setting, setLocal_safety_deposit_setting] = useState(false);
    const [local_deposit_amount, setLocal_deposit_amount] = useState(0);
    const [local_auto_refund_setting, setLocal_auto_refund_setting] = useState(false);
    const [local_auto_refund_days, setLocal_auto_refund_days] = useState(7);
    const [local_manual_refund_setting, setLocal_manual_refund_setting] = useState(false);

    // Visible
    const [notificationTableVisible, setNotificationTableVisible] = useState(false);
    const [guestinfoVisible, setGuestinfoVisible] = useState(false);
    const [inventoryTableVisible, setInventoryTableVisible] = useState(false);
    const [serviceTableVisible, setServiceTableVisible] = useState(false);
    const [guideTableVisible, setGuideTableVisible] = useState(false);
    const [cityTaxVisible, setCityTaxVisible] = useState(false);
    const [safetyDepositOptions, setSafetyDepositOptions] = useState(false);

    const handleNotificationTableToggle = () => {
        setNotificationTableVisible((prevState) => !prevState);
    };

    const handleInventoryToggle = () => {
        setInventoryTableVisible((prevState) => !prevState);
    };

    const handleSettingChange = (e) => {
        const name = e.target.name;

        if (name === "notification_setting") {
            setLocalNotification_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_notification_setting: newState });
                return newState;
            });
        } else if (name === "global_guide_book_setting") {
            setLocal_guide_book_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_guide_book_setting: newState });
                return newState;
            });
        } else if (name === "order_setting") {
            setOrder_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ order_setting: newState });
                return newState;
            });
        } else if (name === "global_services_setting") {
            setLocal_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_services_setting: newState });

                if (prevState) {
                    detachAllPropertyServices();
                }
                return newState;
            });
        } else if (name === "checkin_services_setting") {
            setCheckin_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ checkin_services_setting: newState });
                return newState;
            });
        } else if (name === "global_guest_info_setting") {
            setLocal_guest_info_setting((prevState) => {
                const newState = !prevState;
                if (prevState) {
                    setLocalMain_guest_info_setting(false);
                    updatePmSettings({ local_main_guest_info_setting: false });

                    setLocalAdult_info_setting(false);
                    updatePmSettings({ local_adult_info_setting: false });
                }
                updatePmSettings({ local_guest_info_setting: newState });
                return newState;
            });
        } else if (name === "main_guest_info_setting") {
            setLocalMain_guest_info_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setLocalAdult_info_setting(false);
                    updatePmSettings({ local_adult_info_setting: false });
                }
                updatePmSettings({ local_main_guest_info_setting: newState });
                return newState;
            });
        } else if (name === "adult_info_setting") {
            setLocalAdult_info_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setLocalMain_guest_info_setting(false);
                    updatePmSettings({ local_main_guest_info_setting: false });
                }
                updatePmSettings({ local_adult_info_setting: newState });
                return newState;
            });
        } else if (name === "inventory_setting") {
            setLocalInventory_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_inventory_setting: newState });
                return newState;
            });
        } else if (name === "previous_damage_setting") {
            setLocalPrevious_damage_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_previous_damage_setting: newState });
                return newState;
            });
        } else if (name === "id_recognition_setting") {
            setLocalId_recognition_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_id_recognition_setting: newState });
                return newState;
            });
        } else if (name === "checkout_services_setting") {
            setCheckout_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ checkout_services_setting: newState });
                return newState;
            });
        } else if (name === "damage_setting") {
            setLocalDamage_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_damage_setting: newState });
                return newState;
            });
        } else if (name === "while_stay_services_setting") {
            setWhile_stay_services_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ while_stay_services_setting: newState });
                return newState;
            });
        } else if (name === "local_city_tax_setting") {
            setLocal_city_tax_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_city_tax_setting: newState });
                return newState;
            });
        } else if (name === "adult-tax") {
            setLocal_adults_city_tax(e.target.value);
        } else if (name === "kids-tax") {
            setLocal_kids_city_tax(e.target.value);
        } else if (name === 'local_safety_deposit_setting') {
            setLocal_safety_deposit_setting((prevState) => {
                const newState = !prevState;
                updatePmSettings({ local_safety_deposit_setting: newState });
                return newState;
            });
        } else if (name === 'local_deposit_amount') {
            updatePmSettings({ local_deposit_amount: local_deposit_amount });
        } else if (name === 'local_auto_refund_setting') {
            setLocal_auto_refund_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setLocal_manual_refund_setting(false);
                    updatePmSettings({ local_manual_refund_setting: false });
                }
                updatePmSettings({ local_auto_refund_setting: newState });
                return newState;
            });
        } else if (name === 'local_manual_refund_setting') {
            setLocal_manual_refund_setting((prevState) => {
                const newState = !prevState;
                if (!prevState) {
                    setLocal_auto_refund_setting(false);
                    updatePmSettings({ local_auto_refund_setting: false });
                }
                updatePmSettings({ local_manual_refund_setting: newState });
                return newState;
            });
        } else if (name === 'local_auto_refund_days') {
            setLocal_auto_refund_days(e.target.value);
            updatePmSettings({ local_auto_refund_days: e.target.value })
        }
    };

    const detachAllPropertyServices = () => {
        const property_id = propertyId;

        PmService.DetachAllPropertyServices(property_id)
            .then((res) => {
                if (res.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: res.message,
                    });
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const updatePmSettings = (passData) => {
        passData.property_id = propertyId;

        PmService.UpdatePmSettings(passData)
            .then((res) => {
                if (res.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Setting has been changed",
                    });
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const fetchPmSettings = () => {
        const property_id = propertyId;

        PmService.GetPmSettings(property_id)
            .then((res) => {
                const data = res.data;
                console.log(data);

                setLocalNotification_setting(!!data.local_notification_setting);
                setLocal_guide_book_setting(!!data.local_guide_book_setting);
                setLocal_guest_info_setting(!!data.local_guest_info_setting);
                setLocalMain_guest_info_setting(!!data.local_main_guest_info_setting);
                setLocalAdult_info_setting(!!data.local_adult_info_setting);
                setLocalInventory_setting(!!data.local_inventory_setting);
                setLocalPrevious_damage_setting(!!data.local_previous_damage_setting);
                setLocalId_recognition_setting(!!data.local_id_recognition_setting);
                setLocalDamage_setting(!!data.local_damage_setting);
                setLocal_services_setting(!!data.local_services_setting);
                setLocal_city_tax_setting(!!data.local_city_tax_setting);
                setLocal_adults_city_tax(data.local_adults_city_tax);
                setLocal_kids_city_tax(data.local_kids_city_tax);
                setLocal_safety_deposit_setting(!!data.local_safety_deposit_setting);
                setLocal_deposit_amount(data.local_deposit_amount);
                setLocal_auto_refund_setting(!!data.local_auto_refund_setting);
                setLocal_auto_refund_days(data.local_auto_refund_days);
                setLocal_manual_refund_setting(!!data.local_manual_refund_setting);

                console.log("Settings loaded successfully");
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const fetchGlobalPmSettings = () => {
        const property_id = 0;

        PmService.GetPmSettings(property_id)
            .then((res) => {
                const data = res.data;
                console.log(data);

                setNotification_setting(!!data.notification_setting);
                setGlobal_guide_book_setting(!!data.global_guide_book_setting);
                setOrder_setting(!!data.order_setting);
                setGlobal_services_setting(!!data.global_services_setting);
                setCheckin_services_setting(!!data.checkin_services_setting);
                setGlobal_guest_info_setting(!!data.global_guest_info_setting);
                setInventory_setting(!!data.inventory_setting);
                setPrevious_damage_setting(!!data.previous_damage_setting);
                setId_recognition_setting(!!data.id_recognition_setting);
                setCheckout_services_setting(!!data.checkout_services_setting);
                setDamage_setting(!!data.damage_setting);
                setWhile_stay_services_setting(!!data.while_stay_services_setting);

                console.log("Settings loaded successfully");
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {
        if (descriptionRef.current) {
            console.log("descriptionRef.current = ", descriptionRef.current);
            const lineHeight = parseInt(window.getComputedStyle(descriptionRef.current).lineHeight, 10);
            const maxHeight = lineHeight * 4; // 4 lines
            if (descriptionRef.current.scrollHeight > maxHeight) {
                setShowButton(true);
            }
        }
        fetchPmSettings();
        fetchGlobalPmSettings();
    }, [propertyDetail?.description]);

    return loader ? (
        <Loader />
    ) : (
        <>
            <div className="form-head page-titles d-flex  align-items-center">
                <div className="me-auto  d-lg-block">
                    <h4 className="mb-1">Property Details</h4>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <Link to="/property-list">Property</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="#">{propertyDetail?.name}</Link>
                        </li>
                    </ol>
                </div>

                {/* {
          propertyDetail?.pdf_link && 
          <Link to={propertyDetail?.pdf_link} className="btn btn-sm btn-primary rounded light me-3" target="blank_">
            E - Guide
          </Link>
        } */}

                <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={propertyStatus}
                    style={{
                        cursor: "pointer",
                        width: "fit-content",
                        fontSize: "14px",
                        backgroundColor: "var(--primary)",
                        color: "white",
                        marginRight: "1rem",
                    }}
                    onChange={(e) => handleStatusChange(e)}
                >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>

                <Button
                    className="btn btn-primary btn-sm rounded light me-3"
                    onClick={() => {
                        console.log(propertyDetail.booking_url);
                        setEditPropertyModal(true);
                        setFormData((prevFormData) => ({
                            ...prevFormData,
                            airbnbIcalLink: propertyDetail.airbnb_url,
                            airbnbPropertyLink: propertyDetail.property_detail_sync_url,
                            pdfLink: propertyDetail.pdf_link,
                        }));
                        setErrors(errorsObj);
                    }}
                >
                    Edit Property{" "}
                </Button>

                <Link to="#" className="btn btn-primary rounded light btn-sm">
                    Refresh
                </Link>
            </div>

            {/* Tab View */}
            <div className="tab-view">
                <div className="tab-headers d-flex align-items-center mb-3">
                    {notification_setting ? (
                        <button
                            className={`tab-button ${activeTab === "Notification" ? "active" : ""}`}
                            onClick={() => setActiveTab("Notification")}
                            style={{ marginRight: "10px" }}
                        >
                            Notification
                        </button>
                    ) : (
                        <></>
                    )}

                    {global_guide_book_setting ? (
                        <button
                            className={`tab-button ${activeTab === "Guide book" ? "active" : ""}`}
                            onClick={() => setActiveTab("Guide book")}
                            style={{ marginRight: "10px" }}
                        >
                            Guide Book
                        </button>
                    ) : (
                        <></>
                    )}

                    <button
                        className={`tab-button ${activeTab === "Order" ? "active" : ""}`}
                        onClick={() => setActiveTab("Order")}
                        style={{ marginRight: "10px" }}
                    >
                        Order
                    </button>
                    <button
                        className={`tab-button ${activeTab === "Services" ? "active" : ""}`}
                        onClick={() => setActiveTab("Services")}
                        style={{ marginRight: "10px" }}
                    >
                        Services
                    </button>
                    <button
                        className={`tab-button ${activeTab === "Check-in" ? "active" : ""}`}
                        onClick={() => setActiveTab("Check-in")}
                        style={{ marginRight: "10px" }}
                    >
                        Check-in
                    </button>
                    <button
                        className={`tab-button ${activeTab === "Check-out" ? "active" : ""}`}
                        onClick={() => setActiveTab("Check-out")}
                        style={{ marginRight: "10px" }}
                    >
                        Check-out
                    </button>
                    <button
                        className={`tab-button ${activeTab === "While stay" ? "active" : ""}`}
                        onClick={() => setActiveTab("While stay")}
                        style={{ marginRight: "10px" }}
                    >
                        While stay
                    </button>
                    <button
                        className={`tab-button ${activeTab === "City tax" ? "active" : ""}`}
                        onClick={() => setActiveTab("City tax")}
                        style={{ marginRight: "10px" }}
                    >
                        City tax
                    </button>
                    <button
                        className={`tab-button ${activeTab === "Safety deposite" ? "active" : ""}`}
                        onClick={() => setActiveTab("Safety deposite")}
                        style={{ marginRight: "10px" }}
                    >
                        Safety deposite
                    </button>
                    <button
                        className={`tab-button ${activeTab === "Invoices" ? "active" : ""}`}
                        onClick={() => setActiveTab("Invoices")}
                        style={{ marginRight: "10px" }}
                    >
                        Invoices
                    </button>
                    <button
                        className={`tab-button ${activeTab === "Team" ? "active" : ""}`}
                        onClick={() => setActiveTab("Team")}
                    >
                        Team
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            {activeTab === "Notification" && notification_setting ? (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                            name="notification_setting"
                                            onClick={handleSettingChange}
                                            checked={local_notification_setting}
                                        />
                                        <div className="feature-details">
                                            <img src={Notification} className="feature-icon" />
                                            <div className="feature-title">Notification</div>
                                            <div className="feature-description">
                                                On top of the standard inbuild SNAPIN notification create your own
                                                notifiaction to boost your revenu
                                            </div>

                                            <div className="feature-price">
                                                <Button
                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                    onClick={handleNotificationTableToggle}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {activeTab === "Guide book" && global_guide_book_setting ? (
                                <div className="feature-card">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        className="feature-toggle"
                                        name="global_guide_book_setting"
                                        onClick={handleSettingChange}
                                        checked={local_guide_book_setting}
                                    />
                                    <div className="feature-details">
                                        <img src={Guide} className="feature-icon" />
                                        <div className="feature-title">Guide book</div>
                                        <div className="feature-description">
                                            Allow your guest to see multiple guides (entrance, equipment
                                            functionalities, wifi set-up, etc..)
                                        </div>
                                        <div className="feature-offer">
                                            <span></span>
                                            <span className="feature-price">
                                                <Button
                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                    onClick={() => {
                                                        setGuideTableVisible((prevState) => !prevState);
                                                    }}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {activeTab === "Order" && (
                                <div className="feature-card">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        className="feature-toggle"
                                        name="order_setting"
                                        onClick={handleSettingChange}
                                        checked={order_setting}
                                    />
                                    <div className="feature-details">
                                        <img src={Order} className="feature-icon" />
                                        <div className="feature-title">Order</div>
                                        <div className="feature-description">
                                            Approve your service orders before payment processing
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === "Services" && (
                                <div className="feature-card">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        className="feature-toggle"
                                        name="global_services_setting"
                                        onClick={handleSettingChange}
                                        checked={local_services_setting}
                                    />
                                    <div className="feature-details">
                                        <img src={Service} className="feature-icon" />
                                        <div className="feature-title">Services</div>
                                        <div className="feature-description">Activate SNAPIN service upselling</div>
                                        <div className="feature-offer">
                                            <span></span>
                                            <span className="feature-price">
                                                <Button
                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                    onClick={() => {
                                                        setServiceTableVisible((prevState) => !prevState);
                                                    }}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === "Check-in" && (
                                <>
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="checkin_services_setting"
                                                onClick={handleSettingChange}
                                                checked={checkin_services_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Service} className="feature-icon" />
                                                <div className="feature-title">Services</div>
                                                <div className="feature-description">
                                                    Activate SNAPIN service upselling during checkin flow
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="global_guest_info_setting"
                                                onClick={handleSettingChange}
                                                checked={local_guest_info_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Guest information management</div>
                                                <div className="feature-description">
                                                    Management which guests must register in SNAPIN
                                                </div>
                                                <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                            onClick={() => {
                                                                setGuestinfoVisible((prevState) => !prevState);
                                                                setInventoryTableVisible(false);
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="inventory_setting"
                                                onClick={handleSettingChange}
                                                checked={local_inventory_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Checklist} className="feature-icon" />
                                                <div className="feature-title">Inventory check-list</div>
                                                <div className="feature-description">
                                                    Create your own entrance inventory checklist
                                                </div>
                                                <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                            onClick={() => {
                                                                handleInventoryToggle();
                                                                setGuestinfoVisible(false);
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="previous_damage_setting"
                                                onClick={handleSettingChange}
                                                checked={local_previous_damage_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Damage} className="feature-icon" />
                                                <div className="feature-title">Previous Damage</div>
                                                <div className="feature-description">
                                                    Allow guest to register previous damage while checkin
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="id_recognition_setting"
                                                onClick={handleSettingChange}
                                                checked={local_id_recognition_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={ID} className="feature-icon" />
                                                <div className="feature-title">ID Recognition</div>
                                                <div className="feature-description">
                                                    Allow your guets to use ID recognition during profile and checkin
                                                    registration
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === "Check-out" && (
                                <>
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="checkout_services_setting"
                                                onClick={handleSettingChange}
                                                checked={checkout_services_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Service} className="feature-icon" />
                                                <div className="feature-title">Services</div>
                                                <div className="feature-description">
                                                    Activate SNAPIN service upselling during checkout flow
                                                </div>
                                            </div>
                                        </div>

                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="damage_setting"
                                                onClick={handleSettingChange}
                                                checked={local_damage_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={Damage} className="feature-icon" />
                                                <div className="feature-title">Damage</div>
                                                <div className="feature-description">
                                                    Register damage while on stay to ensure safety deposite and quick
                                                    repair by the maintenance team
                                                </div>
                                                {/* <div className="feature-offer">
                                                    <span></span>
                                                    <span className="feature-price">
                                                        <Button
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === "While stay" && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                            name="while_stay_services_setting"
                                            onClick={handleSettingChange}
                                            checked={while_stay_services_setting}
                                        />
                                        <div className="feature-details">
                                            <img src={Service} className="feature-icon" />
                                            <div className="feature-title">Services</div>
                                            <div className="feature-description">
                                                Activate SNAPIN service upselling during while on stay flow
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {/* {activeTab === "City tax" && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                            name="local_city_tax_setting"
                                            onClick={handleSettingChange}
                                            checked={local_city_tax_setting}
                                        />
                                        <div className="feature-details">
                                            <img src={Citytax} className="feature-icon" />
                                            <div className="feature-title">City tax</div>
                                            <div className="feature-description">
                                                Let SNAPIN handle your city tax payment during checkin flow
                                            </div>
                                            <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                        onClick={() => setCityTaxVisible((prevState) => !prevState)}
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )} */}
                            {activeTab === "Safety deposite" && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                            name="local_safety_deposit_setting"
                                            onClick={handleSettingChange}
                                            checked={local_safety_deposit_setting}
                                        />
                                        <div className="feature-details">
                                            <img src={SafetyDeposit} className="feature-icon" />
                                            <div className="feature-title">Safety Deposit</div>
                                            <div className="feature-description">
                                                Protect you properties with a safety deposit taken during checkin flow
                                                and decide when and how it will be refund
                                            </div>
                                            <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                        onClick={() => setSafetyDepositOptions((prev) => !prev)}
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === "Invoices" && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                        />
                                        <div className="feature-details">
                                            <img src={Invoice} className="feature-icon" />
                                            <div className="feature-title">Invoices</div>
                                            <div className="feature-description">
                                                Generate directly invoice to your invoice management system
                                            </div>
                                            <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button className="btn btn-primary shadow btn-xs sharp me-1">
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === "Team" && (
                                <>
                                    <div className="feature-card">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            className="feature-toggle"
                                        />
                                        <div className="feature-details">
                                            <img src={Team} className="feature-icon" />
                                            <div className="feature-title">Team</div>
                                            <div className="feature-description">
                                                Create multiple Team member to manage your property, allow them to
                                                receive information
                                            </div>
                                            <div className="feature-offer">
                                                <span></span>
                                                <span className="feature-price">
                                                    <Button className="btn btn-primary shadow btn-xs sharp me-1">
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {activeTab == "Guide book" && guideTableVisible && (
                            <>
                                <div className="card-body">
                                    <EGuide propertyId={propertyId} />
                                </div>
                            </>
                        )}

                        {activeTab == "Check-in" && guestinfoVisible == 1 && (
                            <>
                                <div className="card-body">
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="main_guest_info_setting"
                                                onClick={handleSettingChange}
                                                checked={local_main_guest_info_setting}
                                                disabled={!local_guest_info_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Include Main Guest Only</div>
                                                <div className="feature-description">
                                                    Management which guests must register in SNAPIN
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                className="feature-toggle"
                                                name="adult_info_setting"
                                                onClick={handleSettingChange}
                                                checked={local_adult_info_setting}
                                                disabled={!local_guest_info_setting}
                                            />
                                            <div className="feature-details">
                                                <img src={GuestInfo} className="feature-icon" />
                                                <div className="feature-title">Include Adult Only</div>
                                                <div className="feature-description">
                                                    Management which guests must register in SNAPIN
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {activeTab == "Check-in" && inventoryTableVisible == 1 && (
                            <>
                                <div className="card-body">
                                    <Inventory propertyId={propertyId} />
                                </div>
                            </>
                        )}

                        {notificationTableVisible && activeTab == "Notification" && (
                            <>
                                <ServiceNotification />
                            </>
                        )}

                        {/* {activeTab == "City tax" && cityTaxVisible && (
                            <>
                                <div className="card-body">
                                    <div className="feature-container">
                                        <div className="feature-card">
                                            <div className="feature-details">
                                                <div className="feature-title">Adults/day</div>
                                                <div className="feature-description">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="adult-tax"
                                                        min="0"
                                                        onChange={handleSettingChange}
                                                        value={local_adults_city_tax}
                                                    />
                                                </div>
                                                <div className="feature-offer mb-4">
                                                    <span></span>
                                                    <span
                                                        className="feature-price"
                                                        style={{
                                                            bottom: "8px",
                                                        }}
                                                    >
                                                        <Button
                                                            className="btn btn-primary btn-sm shadow sharp mt-3"
                                                            style={{
                                                                width: "70px",
                                                                height: "25px",
                                                            }}
                                                            onClick={() => {
                                                                updatePmSettings({
                                                                    local_adults_city_tax: local_adults_city_tax,
                                                                });
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-card">
                                            <div className="feature-details">
                                                <div className="feature-title">Kids/day</div>
                                                <div className="feature-description">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="kids-tax"
                                                        min="0"
                                                        onChange={handleSettingChange}
                                                        value={local_kids_city_tax}
                                                    />
                                                </div>
                                                <div className="feature-offer mb-4">
                                                    <span></span>
                                                    <span
                                                        className="feature-price"
                                                        style={{
                                                            bottom: "8px",
                                                        }}
                                                    >
                                                        <Button
                                                            className="btn btn-primary btn-sm shadow sharp mt-3"
                                                            style={{
                                                                width: "70px",
                                                                height: "25px",
                                                            }}
                                                            onClick={() => {
                                                                updatePmSettings({
                                                                    local_kids_city_tax: local_kids_city_tax,
                                                                });
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )} */}

                        {
                            local_safety_deposit_setting
                            && safetyDepositOptions
                            && activeTab == 'Safety deposite' && (
                                <>
                                    <div className="card-body">
                                        <div className="feature-container mb-4">
                                            <label className="form-label mt-2">Amount:</label>
                                            <div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name=""
                                                    value={local_deposit_amount}
                                                    onChange={(e) => setLocal_deposit_amount(e.target.value)}
                                                    min='0'
                                                />
                                            </div>
                                            <Button
                                                name="local_deposit_amount"
                                                onClick={handleSettingChange}
                                                disabled={local_deposit_amount >= 0 ? false : true}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                        <div className="feature-container">
                                            <div className="feature-card">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                    className="feature-toggle"
                                                    name="local_auto_refund_setting"
                                                    onClick={handleSettingChange}
                                                    checked={local_auto_refund_setting}
                                                    disabled={!local_safety_deposit_setting}
                                                />
                                                <div className="feature-details">
                                                    <img src={GuestInfo} className="feature-icon" />
                                                    <div className="feature-title">Auto refund</div>
                                                    <div className="feature-description">
                                                        Refund will be given after selected days
                                                    </div>
                                                    <div className="feature-description">
                                                        <select
                                                            name="local_auto_refund_days"
                                                            className="form-select form-control-lg rounded"
                                                            value={local_auto_refund_days}
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "fit-content",
                                                                fontSize: "14px",
                                                                backgroundColor: "var(--primary)",
                                                                color: "white",
                                                                marginRight: "10px"
                                                            }}
                                                            onChange={handleSettingChange}
                                                            disabled={local_manual_refund_setting || !local_auto_refund_setting}
                                                        >
                                                            <option value="3">3 days</option>
                                                            <option value="5">5 days</option>
                                                            <option value="7">7 days</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feature-card">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                    className="feature-toggle"
                                                    name="local_manual_refund_setting"
                                                    onClick={handleSettingChange}
                                                    checked={local_manual_refund_setting}
                                                    disabled={!local_safety_deposit_setting}
                                                />
                                                <div className="feature-details">
                                                    <img src={GuestInfo} className="feature-icon" />
                                                    <div className="feature-title">Manual refund</div>
                                                    <div className="feature-description">
                                                        PM decides to give manual refund
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="row">
                {local_services_setting && serviceTableVisible && (
                    <div className="col-xl-12">
                        <Card>
                            <div className="card-header border-0 pb-0">
                                <h3 className="fs-20 text-black mb-0">Services</h3>
                            </div>

                            <Card.Body>
                                <Tab.Container defaultActiveKey={"List"}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={"List"}>
                                            <div className="row">
                                                {mainServiceList.map((item, ind) => (
                                                    <div
                                                        className="col-xl-3 col-xxl-3 col-md-4 col-sm-4 col-lg-4 m-b30"
                                                        key={ind}
                                                    >
                                                        <div className="property-card style-1">
                                                            <div className="dz-media">
                                                                <img
                                                                    className="fix-height-250"
                                                                    src={item.image}
                                                                    alt="/"
                                                                />
                                                            </div>
                                                            <div className="dz-content">
                                                                <div className="dz-footer">
                                                                    <h5 className="title">{item.service}</h5>
                                                                    <div className="dz-meta">
                                                                        <ul>
                                                                            <li>
                                                                                <Link
                                                                                    to={"#"}
                                                                                    className="rent badge badge-sm badge-primary mt-2"
                                                                                    onClick={() =>
                                                                                        handleAddService(item.id)
                                                                                    }
                                                                                >
                                                                                    Edit{" "}
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="14"
                                                                                        height="14"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        className="feather feather-plus"
                                                                                        style={{
                                                                                            marginRight: "unset",
                                                                                        }}
                                                                                    >
                                                                                        <line
                                                                                            x1="12"
                                                                                            y1="5"
                                                                                            x2="12"
                                                                                            y2="19"
                                                                                        ></line>
                                                                                        <line
                                                                                            x1="5"
                                                                                            y1="12"
                                                                                            x2="19"
                                                                                            y2="12"
                                                                                        ></line>
                                                                                    </svg>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
                                                                    <p className="mb-0 fs-10">
                                                                        <span className="badge-outline-secondary badge badge-rounded bs-pointer">
                                                                            {item?.service_added} services added{" "}
                                                                        </span>
                                                                    </p>
                                                                    <p className="mb-0 fs-10 mt-0">
                                                                        <span className="badge-primary badge badge-rounded bs-pointer">
                                                                            {item?.total_sub_services} services total{" "}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Card.Body>
                        </Card>
                    </div>
                )}

                {/* <div className="col-xl-12 col-xxl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-6 mb-sm-5 mb-3">
                      <div className="front-view-slider">
                        {propertyDetail?.photos && propertyDetail?.photos?.length > 0 && (
                          <img
                            src={propertyDetail?.photos[0]?.url}
                            alt=""
                            className="object-fit-cover rounded-lg"
                            style={{ height: "450px", width: "100%" }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 mb-sm-5 mb-3">
                      {propertyDetail?.photos && propertyDetail?.photos?.length > 0 && (
                        <div className="card" style={{ boxShadow: 'unset' }}>
                          <div className="card-body image-gallery-body">
                            <div className="image-gallery owl-carousel mt-4">
                              <ImageGallery photos={propertyDetail.photos} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="d-block mb-sm-5 mb-2">
                      <div className="me-auto mb-md-0">
                        <h3>{propertyDetail?.name}</h3>
                      </div>
                      <div className="description d-flex align-items-center mb-2">
                        {propertyDetail?.address && (<div style={{ marginRight: '10px' }}>
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9475 4.78947C8.94136 4.78947 7.02346 5.55047 5.61418 6.89569C4.20599 8.23987 3.42116 10.056 3.42116 11.9426C3.42116 14.7033 5.29958 17.3631 7.32784 19.4068C8.3259 20.4124 9.32653 21.2351 10.0786 21.8068C10.434 22.077 10.7326 22.29 10.9475 22.4389C11.1623 22.29 11.4609 22.077 11.8163 21.8068C12.5684 21.2351 13.569 20.4124 14.5671 19.4068C16.5954 17.3631 18.4738 14.7033 18.4738 11.9426C18.4738 10.056 17.689 8.23987 16.2808 6.89569C14.8715 5.55047 12.9536 4.78947 10.9475 4.78947ZM10.9475 23.2632C10.5801 23.8404 10.58 23.8403 10.5797 23.8401L10.5792 23.8398L10.5774 23.8387L10.5718 23.835L10.5517 23.8221C10.5345 23.8109 10.5097 23.7948 10.4779 23.7737C10.4143 23.7317 10.3224 23.6701 10.2063 23.5901C9.97419 23.43 9.64481 23.1959 9.25054 22.8962C8.46315 22.2977 7.41114 21.4333 6.35658 20.3707C4.27957 18.278 2.05273 15.2776 2.05273 11.9426C2.05273 9.67199 2.99797 7.50121 4.66932 5.90583C6.33959 4.31148 8.59845 3.42105 10.9475 3.42105C13.2965 3.42105 15.5554 4.31148 17.2256 5.90583C18.897 7.50121 19.8422 9.67199 19.8422 11.9426C19.8422 15.2776 17.6154 18.278 15.5384 20.3707C14.4838 21.4333 13.4318 22.2977 12.6444 22.8962C12.2501 23.1959 11.9207 23.43 11.6886 23.5901C11.5725 23.6701 11.4806 23.7317 11.417 23.7737C11.3979 23.7864 11.3814 23.7972 11.3675 23.8063C11.3582 23.8124 11.3501 23.8176 11.3432 23.8221L11.3232 23.835L11.3175 23.8387L11.3158 23.8398L11.3152 23.8401C11.315 23.8403 11.3148 23.8404 10.9475 23.2632ZM10.9475 23.2632L11.3148 23.8404C11.0907 23.983 10.8043 23.983 10.5801 23.8404L10.9475 23.2632Z" fill="#666666" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9474 10.2632C9.81378 10.2632 8.89479 11.1822 8.89479 12.3158C8.89479 13.4494 9.81378 14.3684 10.9474 14.3684C12.0811 14.3684 13.0001 13.4494 13.0001 12.3158C13.0001 11.1822 12.0811 10.2632 10.9474 10.2632ZM7.52637 12.3158C7.52637 10.4264 9.05802 8.89474 10.9474 8.89474C12.8368 8.89474 14.3685 10.4264 14.3685 12.3158C14.3685 14.2052 12.8368 15.7368 10.9474 15.7368C9.05802 15.7368 7.52637 14.2052 7.52637 12.3158Z" fill="#666666" />
                          </svg>
                          {propertyDetail?.address}
                        </div>)}
                        <div className="description d-flex align-items-center">
                          <i
                            class="fa-solid fa-star fa-xl me-2"
                          ></i>
                          {propertyDetail?.ratings} {propertyDetail?.total_reviews_count && '· ' + propertyDetail?.total_reviews_count + ' reviews'}
                        </div>
                      </div>
                    </div>
                    <div className="mb-sm-5 mb-2">
                      {propertyDetail?.bedroom && (
                        <Link to="#" className="btn btn-primary light rounded me-2 mb-sm-0 mb-2">
                          <i className="flaticon-381-blueprint me-2" width={28}
                            height={19} />
                          {propertyDetail?.bedroom} Bedroom
                        </Link>
                      )}
                      {propertyDetail?.beds && (
                        <Link to="#" className="btn btn-primary light rounded me-2 mb-sm-0 mb-2">
                          <svg
                            className="me-2"
                            width={28}
                            height={19}
                            viewBox="0 0 28 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.1 8.03846C25.7498 8.03846 28 10.2859 28 13.1538V17.5385H25.9V19H24.5V17.5385H3.5V19H2.1V17.5385H0V13.1538C0 10.3876 2.17398 8.03846 4.9 8.03846H23.1ZM21.7 0C23.5821 0 25.2005 1.57962 25.2 3.65385L25.2005 7.14522C24.5639 6.78083 23.8517 6.57692 23.1 6.57692L21.7 6.57618C21.7 5.32466 20.7184 4.38462 19.6 4.38462H15.4C14.8622 4.38462 14.3716 4.59567 14.0001 4.94278C13.629 4.59593 13.1381 4.38462 12.6 4.38462H8.4C7.24044 4.38462 6.30038 5.36575 6.3 6.57619L4.9 6.57692C4.14851 6.57692 3.43653 6.7807 2.8 7.14488V3.65385C2.8 1.68899 4.3096 0 6.3 0H21.7ZM12.6 5.84579C12.9799 5.84579 13.3 6.21117 13.3 6.57692L7.7 6.57618C7.7 6.12909 8.04101 5.84615 8.4 5.84615L12.6 5.84579ZM19.6 5.85107C19.9961 5.84578 20.2996 6.20175 20.3 6.57618H14.7C14.7 6.1227 15.041 5.84615 15.4 5.84615L19.6 5.85107Z"
                              fill="#9832FF"
                            />
                          </svg>
                          {propertyDetail?.beds} beds
                        </Link>
                      )}
                      {propertyDetail?.bathroom && (
                        <Link to="#" className="btn btn-primary light rounded me-2 mb-sm-0 mb-2">
                          <svg
                            className="me-2"
                            width={19}
                            height={22}
                            viewBox="0 0 19 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 10.4211L18.6388 12.249C18.0616 15.1706 15.4406 17.3684 12.5829 17.3684H11.6923L13.4082 22H2.28779V10.4211H19ZM5.14753 0C6.68536 0 8.00727 1.29706 8.00727 2.89474V7.52632H18.8743V8.68421H8.00727V9.26316H1.1439L1.14378 11.0001C0.481336 10.4964 0 9.64309 0 8.68421V2.89474C0 1.33809 1.25234 0 2.85974 0H5.14753Z"
                              fill="#9832FF"
                            />
                          </svg>
                          {propertyDetail?.bathroom} Bathroom
                        </Link>
                      )}
                      {propertyDetail?.guests && (
                        <Link to="#" className="btn btn-primary light rounded mb-sm-0 mb-2">
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 19 22"
                            fill="none"
                            stroke="#3B4CB8"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" fill="#9832FF"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                          {propertyDetail?.guests} Guests
                        </Link>
                      )}
                    </div>
                    <h4>Description</h4>
                    <div ref={descriptionRef}
                      className={`description ${isExpanded ? 'expanded' : 'collapsed'}`}>
                      {propertyDetail?.description}
                    </div>
                    {showButton && (
                      <button onClick={toggleExpand} className="btn btn-link p-0">
                        {isExpanded ? 'Show less' : 'Show more'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

                {/* <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Property Features</h3>
            </div>
            <div className="card-body">
              <ul>
                {(propertyDetail?.feature && propertyDetail?.feature?.length > 0) ? propertyDetail?.feature?.map((item, ind) => (
                  <li key={ind} style={{ width: 'unset', marginRight: '10px' }}>
                    <i className="las la-check-circle" />
                    {item?.feature}
                  </li>
                )) : (<>No Features as of now</>)}
              </ul>
            </div>
          </div>
        </div> */}

                {/* <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Cancellation</h3>
            </div>
            <div className="card-body">
              {propertyDetail?.cancellation}
            </div>
          </div>
        </div> */}

                {/* <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Rules</h3>
            </div>
            <div className="card-body">
              <ul>
                {(propertyDetail?.rules && propertyDetail?.rules?.length > 0) ? propertyDetail?.rules?.map((item, ind) => (
                  <li key={ind} style={{ width: 'unset', marginRight: '10px' }}>
                    <i class="fa-solid fa-circle-info" />
                    {item?.rules}
                  </li>
                )) : (<>No Rules as of now</>)}
              </ul>
            </div>
          </div>
        </div> */}

                {/* <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Security & Place</h3>
            </div>
            <div className="card-body">
              <ul>
                {(propertyDetail?.safety && propertyDetail?.safety?.length > 0) ? propertyDetail?.safety?.map((item, ind) => (
                  <li key={ind} style={{ width: 'unset', marginRight: '10px' }}>
                    <i class="fa-solid fa-circle-info" />
                    {item?.safety}
                  </li>
                )) : (<>No Security & Place as of now</>)}
              </ul>
            </div>
          </div>
        </div> */}

                {/* <div className="col-12">
          <div className="filter cm-content-box box-primary">
            <div className="content-title SlideToolHeader" onClick={() => setOpenMenu(!openMenu)} >
              <h3 className="fs-20 text-black mb-0">Reviews</h3>
              <div className="tools">
                <Link to={"#"} className={`handle ${openMenu ? "expand" : "collpase"}`}>
                  <i className="fal fa-angle-down" />
                </Link>
              </div>
            </div>
            <Collapse in={openMenu}>
              <div className="cm-content-body form excerpt">
                <div className="card-body">
                  {propertyDetail?.reviews && propertyDetail?.reviews.length > 0 ? (
                    propertyDetail.reviews.map((review, ind) => (
                      <div key={ind} className="mb-4">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-0 d-flex">
                            <i className="fa-solid fa-user text-primary me-2" />
                            <div>{review.name}&nbsp;</div>
                            <span className="text-muted text-truncate">
                              {" - "}{review.place.length > 25 ? `${review.place.substring(0, 25)}...` : review.place}
                            </span>
                          </h5>
                        </div>
                        <p className="mt-2">{review.review}</p>
                      </div>
                    ))
                  ) : (
                    <p>No Reviews as of now</p>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        </div> */}
            </div>

            {/* <!-- Add Services Modal --> */}
            <Modal
                className="fade"
                show={addSubServiceModal}
                onHide={() => setAddSubServiceModal(false)}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Add Services</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setAddSubServiceModal(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive className="custom_service_table">
                        <thead>
                            <tr>
                                <th className="width50 "></th>
                                <th>No</th>
                                <th>Origin</th>
                                <th>Service Category</th>
                                <th>Service Title</th>
                                <th>Available At</th>
                                <th>Description</th>
                                <th>Notes</th>
                                <th>Price</th>
                                <th>Details</th>
                                <th>Top Service</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subServiceLoader ? (
                                <tr>
                                    <td colSpan="7">
                                        <ModalLoader />
                                    </td>
                                </tr>
                            ) : subServices?.length > 0 ? (
                                subServices.map((item, ind) => (
                                    <tr key={ind}>
                                        <td>
                                            <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkbox-${ind}`}
                                                    required=""
                                                    checked={item.service_added}
                                                    onChange={(e) => handleCheckboxChange(e, item.id)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <strong>{ind + 1}</strong>
                                        </td>
                                        <td>
                                            <strong>{item.role == 2 ? "Admin" : "Self"}</strong>
                                        </td>
                                        <td>{item.service_category}</td>
                                        <td>{item.service_title}</td>
                                        <td>
                                            {item?.available_at?.includes("check_in") && (
                                                <span className="badge badge-info me-1">Check In</span>
                                            )}
                                            {item?.available_at?.includes("while_stay") && (
                                                <span className="badge bg-light text-dark me-1">While Stay</span>
                                            )}
                                            {item?.available_at?.includes("check_out") && (
                                                <span className="badge bg-dark">Check Out</span>
                                            )}
                                        </td>
                                        <td className="text-truncate" style={{ maxWidth: "150px" }}>
                                            {item.desc_}
                                        </td>
                                        {item.service_added == 1 ? (
                                            item?.pm_desc == null || item?.pm_desc == "" ? (
                                                <td>
                                                    <Link
                                                        to={"#"}
                                                        className="rent badge badge-sm badge-primary mt-2"
                                                        onClick={() => handlePmDescModal(item.id, item?.pm_desc, "add")}
                                                    >
                                                        Add{" "}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-plus"
                                                        >
                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        </svg>
                                                    </Link>
                                                </td>
                                            ) : (
                                                <td>
                                                    <Link
                                                        to={"#"}
                                                        className="rent badge badge-sm badge-primary mt-2"
                                                        onClick={() =>
                                                            handlePmDescModal(item.id, item?.pm_desc, "edit")
                                                        }
                                                    >
                                                        Edit{" "}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-plus"
                                                        >
                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        </svg>
                                                    </Link>
                                                </td>
                                            )
                                        ) : (
                                            <td>
                                                <span className="badge-outline-secondary badge badge-rounded">
                                                    Please attach service first!
                                                </span>
                                            </td>
                                        )}
                                        <td>€{item.role == 2 ? item.pm_price ?? item.price : item.price}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    setSubServiceDetailModal(true);
                                                    setSubServiceDetail(item);
                                                }}
                                                className="btn btn-primary shadow btn-xs sharp me-1"
                                            >
                                                <i className="fa fa-eye"></i>
                                            </Button>
                                        </td>
                                        <td>
                                            <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkbox-${ind}`}
                                                    required=""
                                                    checked={item.is_top_service}
                                                    onChange={(e) => handleTopServiceCheckboxChange(e, item.id)}
                                                    disabled={!item.service_added}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No services found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => setAddSubServiceModal(false)} variant="primary">
                        {" "}
                        Close{" "}
                    </Button>
                    <Button
                        onClick={() => handleAttachSubService()}
                        disabled={passingData.length > 0 ? false : true}
                        variant="success"
                    >
                        {" "}
                        Ok{" "}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <!-- Service Detail Modal --> */}
            <Modal
                className="fade"
                show={subServiceDetailModal}
                onHide={() => setSubServiceDetailModal(false)}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Service Details</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setSubServiceDetailModal(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <strong>Service Title:</strong>
                            </div>
                            <div className="col-md-9">{subServiceDetail.service_title}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <strong>Origin:</strong>
                            </div>
                            <div className="col-md-9">{subServiceDetail.role == 2 ? "Admin" : "Self"}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <strong>Service Category:</strong>
                            </div>
                            <div className="col-md-9">{subServiceDetail.service_category}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <strong>Description:</strong>
                            </div>
                            <div className="col-md-9">{subServiceDetail.desc_}</div>
                        </div>
                        {subServiceDetail.service_added == 1 && (
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <strong>Notes:</strong>
                                </div>
                                <div className="col-md-9">
                                    {subServiceDetail.pm_desc ? subServiceDetail.pm_desc : "No additional notes"}
                                </div>
                            </div>
                        )}
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <strong>Price:</strong>
                            </div>
                            <div className="col-md-9">€{subServiceDetail.pm_price || subServiceDetail.price}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <strong>Available At:</strong>
                            </div>
                            <td className="col-md-9">
                                {subServiceDetail?.available_at?.includes("check_in") && (
                                    <span className="badge badge-info me-1">Check In</span>
                                )}
                                {subServiceDetail?.available_at?.includes("while_stay") && (
                                    <span className="badge bg-light text-dark me-1">While Stay</span>
                                )}
                                {subServiceDetail?.available_at?.includes("check_out") && (
                                    <span className="badge bg-dark">Check Out</span>
                                )}
                            </td>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setSubServiceDetailModal(false)} variant="danger">
                        {" "}
                        Close{" "}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <!-- Dettach Services Modal --> */}
            <Modal className="fade" show={dettachSubServiceModal} centered>
                <Modal.Header>
                    <Modal.Title>Remove Service</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => handleDettachService(false)}></Button>
                </Modal.Header>
                <Modal.Body>Are you sure want to remove this service?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleDettachService(false)} variant="danger">
                        {" "}
                        No{" "}
                    </Button>
                    <Button onClick={() => handleDettachService(true)} variant="success">
                        {" "}
                        Yes{" "}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <!-- Attach Services Modal --> */}
            <Modal className="fade" show={attachSubServiceModal} centered>
                <Modal.Header>
                    <Modal.Title>Add Service</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => handleAttachService(false)}></Button>
                </Modal.Header>
                <Modal.Body>This service for this Property or All Properties?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleAttachService(false)} variant="danger">
                        {" "}
                        Cancel{" "}
                    </Button>
                    {/* <Button onClick={() => setAttachSubServiceModal(false)}> For All </Button>
              <Button onClick={() => setAttachSubServiceModal(false)}>This only</Button> */}
                    <Button onClick={() => handleAttachService(true, "for_all")} variant="info">
                        {" "}
                        For All{" "}
                    </Button>
                    <Button onClick={() => handleAttachService(true, "this_only")} variant="warning">
                        This only
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <!-- Add Pm Note Modal --> */}
            <Modal className="fade" show={addPmDescModal} centered>
                <Modal.Header>
                    <Modal.Title>{pmDescAdd ? "Add" : "Edit"} Note</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setAddPmDescModal(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="mb-12 col-lg-12 col-md-12 mb-4">
                                <label className="form-label">Note</label>
                                <div>
                                    <textarea
                                        className="form-control"
                                        placeholder="Add description"
                                        value={pmDesc}
                                        onChange={(e) => {
                                            setPmDesc(e.target.value);
                                            setErrors({
                                                ...errors,
                                                pmDesc: "",
                                            });
                                        }}
                                    />
                                    {errors.pmDesc && <div className="text-danger fs-12">{errors.pmDesc}</div>}
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setPmDesc("");
                            setAddPmDescModal(false);
                        }}
                        variant="danger"
                    >
                        {" "}
                        Cancel{" "}
                    </Button>
                    <Button onClick={() => handleAddPmDesc()} variant="success">
                        {" "}
                        Save{" "}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*property status change modal */}
            <Modal className="fade" show={confirmModalOpen} onHide={() => handleStatusChangeApi(false)} centered>
                <Modal.Header>
                    <Modal.Title>Property Status</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => handleStatusChangeApi(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to update this property status ? </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleStatusChangeApi(false)} variant="danger">
                        {" "}
                        Close{" "}
                    </Button>
                    <Button onClick={() => handleStatusChangeApi(true)} variant="success">
                        {" "}
                        Yes{" "}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* edit property modal */}
            {editPropertyModal && (
                <>
                    <Modal
                        className="fade"
                        show={setEditPropertyModal}
                        onHide={() => setEditPropertyModal(false)}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title>Edit Property</Modal.Title>
                            <Button
                                variant=""
                                className="btn-close"
                                onClick={() => setEditPropertyModal(false)}
                            ></Button>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div className="row">
                                    <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                        <label className="form-label">PMS Ical</label>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="airbnbIcalLink"
                                                value={formData.airbnbIcalLink}
                                                onChange={handleChange}
                                            />
                                            {errors.airbnbIcalLink && (
                                                <div className="text-danger fs-12">{errors.airbnbIcalLink}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                        <label className="form-label">Airbnb property link</label>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="airbnbPropertyLink"
                                                value={formData.airbnbPropertyLink}
                                                onChange={handleChange}
                                            />
                                            {errors.airbnbPropertyLink && (
                                                <div className="text-danger fs-12">{errors.airbnbPropertyLink}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                        <label className="form-label">E-Guide Link (optional)</label>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="pdfLink"
                                                value={formData.pdfLink}
                                                onChange={handleChange}
                                            />
                                            {errors.pdfLink && (
                                                <div className="text-danger fs-12">{errors.pdfLink}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => setEditPropertyModal(false)} variant="primary">
                                {" "}
                                No{" "}
                            </Button>
                            <Button onClick={handleEditProperty}> Save </Button>
                        </Modal.Footer>
                    </Modal>
                    {loading && (
                        <div className="progress-overlay">
                            <div className="progress-container">
                                <ProgressBar animated now={progress} label={`${progress}%`} />
                                <div className="progress-message">Fetching data, please wait...</div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
export default PropertyDetails;
