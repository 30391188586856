import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import PmService from "../../../services/PmService";
import moment from "moment";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import { useTable, useGlobalFilter, useFilters, usePagination } from "react-table";
import { ColumnFilter } from "../FilteringTable/ColumnFilter";
import { GlobalFilter } from "../FilteringTable/GlobalFilter";
import { format } from "date-fns";

const SafetyDeposit = () => {
    const COLUMNS = [
        {
            Header: "Booking",
            Footer: "Booking",
            accessor: "booking_id",
            Filter: ColumnFilter,
        },
        {
            Header: "Customer",
            Footer: "Customer",
            accessor: "user",
            Filter: ColumnFilter,
        },
        {
            Header: "Property",
            Footer: "Property",
            accessor: "property",
            Filter: ColumnFilter,
        },
        {
            Header: "Amount",
            Footer: "Amount",
            accessor: "amount",
            Filter: ColumnFilter,
        },
        {
            Header: "Type",
            Footer: "Type",
            accessor: "refund_type",
            Filter: ColumnFilter,
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: "status",
            Filter: ColumnFilter,
        },
        {
            Header: 'Action',
            Footer: 'Action',
            accessor: 'action',
            Filter: ColumnFilter,
            Cell: ({ value, row }) => {
                return (
                    <>
                        {
                            row?.original?.refund_type === 'manual'
                            ? (
                                <>
                                    {
                                        row?.original?.status === 'open'
                                        ? (
                                            <Button
                                                name="refund-submit"
                                                onClick={() => {
                                                    setRefundData(row?.original);
                                                    setRefundModal(true);
                                                }}
                                            >
                                                Refund
                                            </Button>
                                        )
                                        : (
                                            <>Refunded</>
                                        )
                                    }
                                </>
                            )
                            : (
                                <>
                                    {
                                        row?.original?.status === 'open'
                                        ? (
                                            <>{row?.original?.auto_refund_days} days refund</>
                                        )
                                        : (
                                            <>Refunded</>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                )
            }
        },
    ];

    const [safetyDeposit, setSafetyDeposit] = useState([]);
    const [refundModal, setRefundModal] = useState(false);
    const [damageAmount, setDamageAmount] = useState(0);
    const [refundData, setRefundData] = useState({});

    const columns = useMemo(() => COLUMNS, []);
    const data = useMemo(() => safetyDeposit, [safetyDeposit]);

    const fetchAllSafetyDeposits = async () => {
        PmService.GetSafetyDeposit()
            .then((resp) => {
                if (resp?.success) {
                    console.log("Deposits ============ ", resp?.data)
                    setSafetyDeposit(resp?.data);
                }
            })
            .catch((err) => console.log("Error: ", err));
    };

    useEffect(() => {
        fetchAllSafetyDeposits();
    }, []);

    const handleRefundSubmit = async () => {
        if (damageAmount > refundData.amount) {
            return;
        }

        refundData.damage_amount = damageAmount;

        PmService.SafetyDepositRefund(refundData)
            .then((resp) => {
                if(resp.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Refund has been proccessed.",
                    });
                    setRefundModal(false);
                    fetchAllSafetyDeposits();
                }
            })
            .catch(err => console.log("Error: ", err))
            .finally(() => {
                setRefundModal(false)
            });
    };

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance;

    const { globalFilter, pageIndex } = state;

    return (
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive table-hover fs-14 order-list-table">
                                <div className="table-responsive">
                                    <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps()}>
                                                            {column.render("Header")}
                                                            {column.canFilter ? column.render("Filter") : null}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()} className="">
                                            {page.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    {" "}
                                                                    {cell.render("Cell")}{" "}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-between">
                                        <span>
                                            Page{" "}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}
                                            </strong>
                                            {""}
                                        </span>
                                        <span className="table-index">
                                            Go to page :{" "}
                                            <input
                                                type="number"
                                                className="ms-2"
                                                defaultValue={pageIndex + 1}
                                                onChange={(e) => {
                                                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                                    gotoPage(pageNumber);
                                                }}
                                            />
                                        </span>
                                    </div>
                                    <div className="text-center">
                                        <div className="filter-pagination  mt-3">
                                            <button
                                                className=" previous-button"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<<"}
                                            </button>

                                            <button
                                                className="previous-button"
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                className="next-button"
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            >
                                                Next
                                            </button>
                                            <button
                                                className=" next-button"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                {">>"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="fade"
                show={refundModal}
                onHide={() => setRefundModal(false)}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Refund safety deposit</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setRefundModal(false)}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <p>Please provide damage amount:</p>
        
                    <div className="mb-3 col-12">
                        <label className="form-label">Amount:</label>
                        <div>
                            <input
                                className="form-control"
                                type="number"
                                name="damage_amount"
                                min="0"
                                value={damageAmount}
                                onChange={(e) => setDamageAmount(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setRefundModal(false)} variant="primary">
                        Close
                    </Button>
                    <Button onClick={handleRefundSubmit} disabled={damageAmount < 0 || damageAmount > refundData.amount}>Yes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SafetyDeposit;
